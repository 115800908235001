import React, { useState } from 'react'
import ReactDOM from 'react-dom'

const LocationSelect = ({ data, prefix, title, iconclass }) => {

	const [ selected, setSelected ] = useState( '' )

	const redirect = ( slug ) => {
		setSelected( slug )
		history.pushState(undefined, 'Home', url);
		let url = '/' + slug;
		if( prefix ) {
			url = '/' + prefix + '/' + slug;
		}
		window.location.replace(url);
	}
	
	const defaultValue = title != undefined ? title : 'Select a destination'

	return (
		<div className="header__image--select-holder">
			<div className="grid-x grid-padding-x">
				<div className="small-12 cell">
					<label htmlFor="header-image-select" style={{fontSize: '0',height: '0', margin:'0'}}>location select</label>
					<div className={'location-select-box styled-select ' + iconclass}>
						<select value={selected} className="header__image--select" name="q" id="header-image-select" onChange={(e) => redirect(e.target.value)}>
							<option defaultValue="">{defaultValue}</option>
							{data.map(function(location, index) {
							return (
								<option value={location.slug} key={location.id}>{location.name}</option>
							)
							})}

						</select>
					</div>
				</div>
			</div>
		</div>
	)
}

document.addEventListener('DOMContentLoaded', () => {

	var itemList = document.querySelectorAll('.react-location-select');
	var items = [...itemList];

	items.forEach(select => {

		const data = JSON.parse(select.getAttribute('locations'));
		const prefix = select.getAttribute('prefix');
		const title = select.getAttribute('title');
		const iconclass = select.getAttribute('iconclass');
	
		ReactDOM.render(
			<LocationSelect data={data} prefix={prefix} title={title} iconclass={iconclass} />,
			select,
		)
	});
})
